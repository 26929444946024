@font-face {
    font-family: 'ZiHunChaoJiHei';
    src: url('../fonts/zihun-chaojihei.ttf');
    src: url('../fonts/zihun-chaojihei.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Microsoft YaHei';
    src: url('../fonts/chinese.msyh.ttf');
    src: url('../fonts/chinese.msyh.ttf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Microsoft YaHei Bold';
    src: url('../fonts/Microsoft-YaHei-Bold.ttf');
    src: url('../fonts/Microsoft-YaHei-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}

.registerBg {
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
}

.registerImg {
    background: url('../../assets/img/bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.disabled-btn{
    border-radius: 12px;
    background: #D9D9D9;
    cursor: pointer;
    padding: .8rem 1.5rem;
    border: none !important;
}

.register-btn {
    background: linear-gradient(181deg, #BC9467 0.69%, #EDC18A 46.51%, #83613F 99.23%);
    border-radius: 12px;
    transition: transform 500ms ease-out;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    // border: 1px solid $primary_btn_bg;
    padding: .8rem 1.5rem;
    border: none !important;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
        transform: rotate(-45deg);
    }

    .btnText {
        color: #202020;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1px;
    }

    &:hover,
    &:active,
    &:focus {
        background: linear-gradient(181deg, #BC9467 0.49%, #EDC18A 43.51%, #83613F 100.23%);
        // box-shadow: 0 0.5em 0.5em -0.4em $primary_btn_box_shadow;
        animation: moveUp 1s ease 0s 1 forwards;

        &::before {
            left: 100%;
        }
    }

}


.logo {
    width: 20%;
}

.big-title {
    background: var(--Linear, linear-gradient(207deg, #BC9467 15.92%, #EDC18A 51.71%, #83613F 92.88%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'ZiHunChaoJiHei' !important;
    font-size: 100px;
    font-style: normal;
    font-weight: 400;
    line-height: 100px;
    /* 58% */

    @media screen and (max-width: 667px) {
        font-size: 5em;
        line-height: 1em;
    }
}

.small-title {
    color: #FFF;
    font-family: 'ZiHunChaoJiHei' !important;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 58px;
    /* 223.077% */
    letter-spacing: 6px;

    @media screen and (max-width: 667px) {
        font-size: 26px;
        line-height: 38px;
    }
}

.sparkle-border {
    mix-blend-mode: lighten;
    position: absolute;
    bottom: -18%;
    left: -9%;
    height: 100;
    width: 100%;

    @media screen and (max-width: 667px) {
        bottom: -18%;
        left: 0%;
    }
}

.event-label {
    display: block;
    color: #ffffff;
    font-family: "Microsoft YaHei";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.event-value {
    display: block;
    color: #ffffff;
    font-family: "Microsoft YaHei";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
}

.input-label {
    color: #84818A;
    font-family: "Microsoft YaHei";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}

.form-title {
    font-family: "Microsoft YaHei Bold";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    background: linear-gradient(207deg, #BC9467 15.92%, #83613F 92.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.form-description {
    color: #202020;
    font-family: "Microsoft YaHei";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bgWhite {
    border-radius: 32px;
    background: #FFF;
}

.referenceLabel {
    color: #202020;
    font-family: "Microsoft YaHei Bold";
    font-size: 12px;
    font-style: normal;
    line-height: normal;
}

.tncLabel {
    color: #202020;
    font-family: "Microsoft YaHei";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cursor-pointer {
    cursor: pointer;
}

input::placeholder {
    color: #202020 !important;
    font-family: "Microsoft YaHei Bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.upload-remark {
    color: #202020 !important;
    font-family: "Microsoft YaHei";
    font-size: 14px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
}

.input-file {
    border-radius: 0px !important;
    border: none !important;
}

.input-transparent {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom-width: 1px;
    border-color: #DCDBDD;

    &:active,
    &:focus {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
        border-bottom-width: 1px;
        border-color: #936F4A;

        &::placeholder {
            color: #936F4A !important;
        }
    }
}

.errorBorder {
    border-color: red !important;
}

.errorBorderFile {
    border: 1px solid red;
}

.text-red {
    color: red !important;
    font-size: 12px;
}

.bank-title {
    color: #84818A;
    font-family: "Microsoft YaHei";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 141.667% */
}

.bank-label {
    color: #202020;
    font-family: "Microsoft YaHei Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.price {
    color: #202020;
    text-align: right;
    font-family: "Microsoft YaHei";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.total-price {
    color: #83613F;
    text-align: right;
    font-family: "Microsoft YaHei Bold";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

.upload-text {
    color: #202020;
    font-family: "Microsoft YaHei";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
}

.grey-container {
    border-radius: 12px;
    background: #D9D9D9;
}

.btn-gold {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    border-radius: 12px;
    background: linear-gradient(181deg, #BC9467 0.69%, #EDC18A 46.51%, #83613F 99.23%);

    .btn-text {
        color: #202020;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

.format-container{
    position: absolute;
    right: 0;
    top: 20%;
}

.format{
    color: #84818A;
    font-family: "Microsoft YaHei";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    @media screen and (max-width: 667px) {
        font-size: 8px;
    }
}

.customSelect__control{
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 1px solid #DCDBDD !important;
    height: calc(1.5em + .75rem + 2px) !important;
    min-height: auto !important;

    &:focus, &:hover{
        border-color: #fff !important;
        border-bottom-color: #936F4A !important;
    }
}

.customSelect__control--is-focused{
    box-shadow: none !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: #936F4A !important;
}

.customSelect__value-container{
    padding: 0px !important;
}

.customSelect__indicator-separator{
    width: 0px !important;
}

.customSelect__indicator{
    svg{
        width: 12px;
        path{
            fill: #000;
            
        }
    }
}

.customSelect__single-value{
    font-size: 0.8571em !important;
}

table{
    overflow: scroll;
}

th{
    font-size: 14px !important;
    min-width: 100px;
}
.tdText{
    font-size: 12px;
}

.life-time-box{
    border-radius: 4px;
    background: linear-gradient(181deg, #BC9467 0.69%, #EDC18A 46.51%, #83613F 99.23%);
}

.life-time-text{
    font-size: 12px;
    color: #000;
    font-style: italic;
    
}